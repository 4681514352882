import React, { useState } from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Heading } from "../components/Hero"
import { Section, Columns } from "../components/Containers"
import { Text } from "../components/Core"

import "../css/theme/blogs-query.scss"

const BlogsQueryPage = ({ pageContext, location, data }) => {
  const blogs = data.blogs.nodes
  const [search, setSearch] = useState("")
  const [filteredBlogs, setFilteredBlogs] = useState(blogs)
  const post = data.allUniquePagesJson.nodes[0]
  const { metaTitle, metaDescription, language } = pageContext

  const handleSearch = input => {
    const value = input.target.value
    setSearch(value)
    if (!search) {
      return setFilteredBlogs(blogs)
    }

    const filter = blogs.filter(blg =>
      blg.title.toLowerCase().includes(value.toLowerCase())
    )

    setFilteredBlogs(filter)
  }

  return (
    <Layout className="blogs-query" language={language}>
      <SEO
        title={metaTitle}
        description={metaDescription}
        pathname={location.pathname}
        lang={language}
      />

      <Heading sideColumnsSize={3} heading={post.heading} />

      <Section>
        <Columns sideColumnsSize={3}>
          <div className="column">
            <div
              className="px-40--mobile"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "6px"
              }}>
              <input
                className="blog-search"
                placeholder="Search..."
                value={search}
                onChange={handleSearch}
                type="text"
              />
              <p
                className="px-0"
                style={{ fontSize: "14px", textWrap: "nowrap" }}>{`${
                filteredBlogs.length
              } blog${filteredBlogs.length === 1 ? "" : "s"}`}</p>
            </div>
          </div>
        </Columns>
      </Section>

      <Section>
        <Columns sideColumnsSize={3}>
          <div className="column">
            <div className="blogs-list">
              {filteredBlogs.map(blg => (
                <div key={blg.title} className="blog-card color-back">
                  <div>
                    {/* <Text
                      className="blog-card--published"
                      as="p"
                      text={blg.published}
                    /> */}
                    <time className="blog-card--published">
                      {blg.published}
                    </time>
                    <Link to={`/${blg.title}`} title={`Read the full article`}>
                      <Text
                        as="h2"
                        className="blog-card--title mt-0 mb-1"
                        text={blg.heading}
                      />
                    </Link>
                  </div>

                  <p className="blog-card--read">
                    <Link to={`/${blg.title}`} title={`Read the full article`}>
                      Read More
                    </Link>
                  </p>
                </div>
              ))}
            </div>
          </div>
        </Columns>
      </Section>
    </Layout>
  )
}

export const blogQuery = graphql`
  query blogsQueryPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        heading
      }
    }
    blogs: allBlogsJson(
      sort: { fields: published, order: DESC }
      filter: { hasThisPage: { eq: true } }
    ) {
      nodes {
        title
        published(formatString: "MMMM Do, YYYY")
        heading
        images
      }
    }
  }
`

export default BlogsQueryPage
